<template>
 <b-row> 
  <b-col class="col-12 col-lg-6">
    <b-card>
      <b-card-body>
        <vue-apex-charts class="cursor-pointer" type="bar" height="300" :options="chart_options_countries.country" :series="series_countries" :key="change_graphics_countries">
        </vue-apex-charts>
      </b-card-body>
      <b-button  @click="showModalCountries" variant="primary" class="primary">{{ $t('creator.edit') }}</b-button>
    </b-card>
  </b-col>
  <b-col class="col-12 col-lg-6">
      <b-card>
        <b-card-body>
          <vue-apex-charts type="bar" height="300" :options="chart_options_type.type" :series="series_type" :key="change_graphics_type"></vue-apex-charts>
        </b-card-body>
        <b-button  @click="showModalType" variant="primary" class="primary">{{ $t('creator.edit') }}</b-button>
      </b-card>
  </b-col>
  <b-col class="col-12 col-lg-6">
   <b-card>
    <vue-apex-charts
        height="235"
        class="cursor-pointer"
        :options="genders"
        :series="series_gender"
        :key="change_graphics"
    >
    </vue-apex-charts>
    <b-row class="text-center mx-0">
    <b-col cols="6" class="border-top border-right d-flex align-items-between flex-column py-1">
      <b-card-text class="text-muted mb-0" v-for="(item, index) in this.genders.labels" :key="index">
        {{ item }}
      </b-card-text>
    </b-col>
    <b-col cols="6" class="border-top d-flex align-items-between flex-column py-1">
    <h4 class="font-weight-bolder mb-50" v-for="(item, index) in this.series_gender" :key="index">
      {{ item }} %
    </h4>
  </b-col>
</b-row>
    <b-button  @click="showModal" variant="primary" class="primary">{{ $t('creator.edit') }}</b-button>
   </b-card>
  </b-col>
  <b-col class="col-12 col-lg-6">
      <b-card :title="title_graphic_age">
        <b-card-body class="pb-0">
          <vue-apex-charts class="cursor-pointer" type="line" height="250" :options="chartOptions" :series="series" :key="change_graphics_age"></vue-apex-charts>
        </b-card-body>
        <b-button  @click="showModalAge" variant="primary" class="primary">{{ $t('creator.edit') }}</b-button>
      </b-card>
  </b-col>
  <b-col class="col-12 col-lg-6">
    <b-card>
      <b-card-body>
        <vue-apex-charts class="cursor-pointer" type="line" height="300" :options="chart_options_followers.followers" :series="series_followers" :key="change_graphics_followers"></vue-apex-charts>
      </b-card-body>
      <b-button  @click="showModalFollowers" variant="primary" class="primary">{{ $t('creator.edit') }}</b-button>
    </b-card>
  </b-col>
  <modal-brandme :show_modal="show_modal" :key="change_modal">
  <template #title>{{$t('creator.editGraphic')}}</template>  
  <template #body>
    <form ref="form">
      <b-form-group :label="$t('creator.titleGraphic')">
        <b-form-textarea v-model="title_graphic_genders"/>
      </b-form-group>
      <b-form-group :label="$t('creator.labelGender')">
        <b-form-textarea v-model="data_genders"/>
      </b-form-group>
      <b-form-group :label="$t('creator.dataLbels')">
        <b-form-textarea v-model="name_graphics_1"/>
      </b-form-group>
      <b-form-group :label="$t('creator.colorsGraphics')">
        <b-form-textarea v-model="colors_graphics_1"/>
      </b-form-group>
    </form>
    <div class="d-flex justify-content-end mt-2">
      <b-button variant="outline-secondary" class="mr-1" @click="closeModal()">{{$t('campaigns.close')}}</b-button>
      <b-button variant="primary" @click="getGraphic()">{{$t('creator.getGraphic')}}</b-button>
    </div>
  </template>
  </modal-brandme>
  <modal-brandme :show_modal="show_modal_age" :key="change_modal_age">
  <template #title>{{$t('creator.editGraphic')}}</template>  
  <template #body>
    <form ref="form">
      <b-form-group :label="$t('creator.titleGraphic')">
        <b-form-textarea v-model="title_graphic_age"/>
      </b-form-group>
      <b-form-group :label="$t('creator.labelGenderAge')">
        <b-form-textarea v-model="data_ageGenders"/>
      </b-form-group>
      <b-form-group :label="$t('creator.rangeGraphic')">
        <b-form-textarea v-model="categories_graphic_2"/>
      </b-form-group>
      <b-form-group :label="$t('creator.colorsGraphics')">
        <b-form-textarea v-model="colors_graphics_2"/>
      </b-form-group>
    </form>
    <div class="d-flex justify-content-end mt-2">
        <b-button variant="outline-secondary" class="mr-1" @click="closeModalAge()">{{$t('campaigns.close')}}</b-button>
        <b-button variant="primary" @click="getGraphicAge()">{{$t('creator.getGraphic')}}</b-button>
    </div>
  </template>
  </modal-brandme>
  <modal-brandme :show_modal="show_modal_countries" :key="change_modal_countries">
  <template #title>{{$t('creator.editGraphic')}}</template>  
  <template #body>
    <form ref="form">
      <b-form-group :label="$t('creator.titleGraphic')">
        <b-form-textarea v-model="title_graphic_countries"/>
      </b-form-group>
      <b-form-group :label="$t('creator.dataLbels')">
        <b-form-textarea v-model="categories_countries"/>
      </b-form-group> 
      <b-form-group :label="$t('creator.labelGender')">
      <b-form-textarea v-model="data_countries"/>
      </b-form-group>
    </form>
    <div class="d-flex justify-content-end mt-2">
      <b-button variant="outline-secondary" class="mr-1" @click="closeModalCountries()">{{$t('campaigns.close')}}</b-button>
      <b-button variant="primary" @click="getGraphicCountries()">{{$t('creator.getGraphic')}}</b-button>
    </div>
  </template>
  </modal-brandme>
  <modal-brandme :show_modal="show_modal_type" :key="change_modal_type">
  <template #title>{{$t('creator.editGraphic')}}</template>  
  <template #body>
    <form ref="form">
      <b-form-group :label="$t('creator.titleGraphic')">
        <b-form-textarea v-model="title_graphic_type"/>
      </b-form-group>
      <b-form-group :label="$t('creator.dataLbels')">
        <b-form-textarea v-model="categories_type"/>
      </b-form-group> 
      <b-form-group :label="$t('creator.labelGender')">
      <b-form-textarea v-model="data_type"/>
      </b-form-group>
    </form>
    <div class="d-flex justify-content-end mt-2">
      <b-button variant="outline-secondary" class="mr-1" @click="closeModalType()">{{$t('campaigns.close')}}</b-button>
      <b-button variant="primary" @click="getGraphicType()">{{$t('creator.getGraphic')}}</b-button>
    </div>
  </template>
  </modal-brandme>
  <modal-brandme :show_modal="show_modal_followers" :key="change_modal_followers"  :size="'lg'">
  <template #title>{{$t('creator.editGraphic')}}</template>  
  <template #body>
    <form ref="form">
      <b-form-group :label="$t('creator.titleGraphic')">
        <b-form-textarea v-model="title_graphic_followers"/>
      </b-form-group>
      <b-form-group :label="$t('creator.dataLbels')">
        <b-form-textarea v-model="categories_followers"/>
      </b-form-group>
      <b-form-group :label="$t('creator.labelGender')">
      <b-form-textarea v-model="data_followers"/>
      </b-form-group>
    </form>
    <div class="d-flex justify-content-end mt-2">
      <b-button variant="outline-secondary" class="mr-1" @click="closeModalFollowers()">{{$t('campaigns.close')}}</b-button>
      <b-button variant="primary" @click="getGraphicFollowers()">{{$t('creator.getGraphic')}}</b-button>
    </div>
  </template>
  </modal-brandme>
 </b-row>  
</template>
    
<script>
import { BCol, BRow, BCard, BButton, BFormGroup, BCardBody, BCardText, BFormTextarea, } from "bootstrap-vue";
import VueApexCharts from 'vue-apexcharts'
import utils from '@/libs/utils'
import ModalBrandme from '@/views/components/modal/ModalBrandme.vue';

export default {
  name: "graphics",
  components: {
    ModalBrandme,
    BFormTextarea,
    BCardText,
    BCardBody,
    BFormGroup,
    VueApexCharts,
    BCol,
    BRow,
    BCard,
    BButton,
  },
  props: {  
  },
  data() {
    return {
      utils,
      change_graphics:  false,
      change_graphics_age:  false,
      change_graphics_countries: false,
      change_graphics_followers: false,
      change_graphics_type: false,
      show_modal: false,
      change_modal: false,
      show_modal_age: false,
      change_modal_age: false,
      show_modal_countries: false,
      change_modal_countries: false,
      show_modal_type: false,
      change_modal_type: false,
      show_modal_followers: false,
      change_modal_followers: false,
      data_genders: [10,10],
      categories_countries: ['México', 'Argentina', 'Colombia', 'Chile', 'España'],
      categories_followers: ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic'],
      categories_type: ['Influencer', 'Masivo', 'Real', 'Sospechoso'],
      data_countries: [{name: this.$t('instagram.porcent'),data: [50, 10, 15, 20, 25]}],
      data_type: [{name: this.$t('instagram.porcent'),data: [50, 40, 30, 20]}],
      data_followers: [{name: this.$t('instagram.followersTooltip'),data: [100, 400, 300, 200, 500, 400, 500, 200, 500, 400, 300, 200,]}],
      title_graphic_genders: `${this.$t('instagram.audienceGenres')}`,
      title_graphic_age: `${this.$t('instagram.ageRange')}`,
      title_graphic_countries: `${this.$t('instagram.mainCountries')}`,
      title_graphic_type: `${this.$t('creator.typeUser')}`,
      title_graphic_followers: `${this.$t('instagram.followersIncrease')}`,
      name_graphics_1: [],
      categories_graphic_2: ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64'],
      colors_graphics_1: ['#5cb3ff', '#E75480'],
      colors_graphics_2: ['#5cb3ff', '#E75480'],
      data_ageGenders: [{name: `${this.$t('instagram.male')}`, data: [0, 10, 15, 20, 25, 30,]}, {name: `${this.$t('instagram.female')}`, data: [5, 25, 10, 20, 15, 0,]}],
      genders: {
        labels: [`${this.$t('instagram.male')}`,`${this.$t('instagram.female')}`],
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter(val) {
              return `${val}%`
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
        colors: ['#5cb3ff', '#E75480'],
        plotOptions: {
          pie: {
            startAngle: 0,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${val}%`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: '',
                  formatter: () => { return  `${this.maxPercentage()}%` },
                },
              },
            },
          },
        },
        title: {
          text: `${this.$t('instagram.audienceGenres')}`,
          offsetY: 10,
          style: {
            fontWeight: 'bold',
            fontSize: '1.285rem',
            color: '#5e5873'
          }
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
      },
      series_gender: [0,0],
      chartOptions: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          dashArray: [0],
          width: [4, 3],
        },
        legend: {
          show: false,
        },
        colors: ['#5cb3ff', '#E75480'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: ['#5cb3ff', '#E75480'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          title: {
            text: '',
            offsetY: -10,
            style: {
              fontWeight: 400,
              fontSize: '1rem',
              color: '#b9b9c3'
            }
          },
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
          },
          axisTicks: {
            show: false,
          },
          categories: ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64'],
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '1rem',
            },
            formatter(val) {
              return `${val}%`
            },
          },
        },
        grid: {
          borderColor: '#e7eef7',
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
      series: [{name: `${this.$t('instagram.male')}`, data: [0, 10, 15, 20, 25, 30,]}, {name: `${this.$t('instagram.female')}`, data: [30, 25, 20, 15, 10, 0,]}],
      chart_options_countries: {
        country: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: '#4be3ac',
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false
          },
          title: {
            text: this.$t('instagram.mainCountries'),
            offsetY: 10,
            style: {
              fontWeight: 'bold',
              fontSize: '1.285rem',
              color: '#5e5873'
            }
          },
          xaxis: {
            categories: ['México', 'Argentina', 'Colombia', 'Chile', 'España'],
            show: true,
            labels: {
              formatter: (val) => {
                return `${utils.decimalFixed(val, 2)}%`
              }
            }
          },
          tooltip: {
            y: {
              formatter: (val) => {
                return `${val}%`
              }
            }
          }
        },
      },
      series_countries: [{name: this.$t('instagram.porcent'),data: [50, 10, 15, 20, 25]}],
      chart_options_type: {
        type: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: '#ea5455',
          plotOptions: {
            bar: {
              horizontal: false,
              barHeight: '30%',
              columnWidth: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false
          },
          title: {
            text: '',
            offsetY: 10,
            style: {
              fontWeight: 'bold',
              fontSize: '1.285rem',
              color: '#5e5873'
            }
          },
          xaxis: {
            categories: ['Influencer', 'Masivo', 'Real', 'Sospechoso']
          },
          yaxis: {
            show: true,
            labels: {
              formatter: (val) => {
                return `${val}%`
              }
            }
          }
        },
      },  
      series_type: [{name: this.$t('instagram.porcent'),data: [50, 40, 30, 20,]}],
      chart_options_followers: {
        followers : {
          chart: {
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: true,
              offsetY: -20,
              tools: {
                download: false,
                selection: false,
                zoom: '<b-icon/>',
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: `<img src="${require('@/assets/images/icons/reset.png')}" width="20">`
              }
            },
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ['#df87f2'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: ['#6c84e0'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          dataLabels: {
            enabled: false
          },
          title: {
            text: '',
            offsetY: 10,
            style: {
              fontWeight: 'bold',
              fontSize: '1.285rem',
              color: '#5e5873'
            }
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: false,
            },
            categories : ['Ene','Feb','Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic']
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                if (val >= 1000000) return `${Math.round(val / 1000000)}M`
                if (val >= 1000) return `${Math.round(val / 1000)}K`
                return val
              },
            },
          },
        },
      },
      series_followers: [{name: this.$t('instagram.followersTooltip'),data: [100, 400, 300, 200, 500, 400, 500, 200, 500, 400, 300, 200,]}],
    }
  },
  created() {
    this.asigData()
    this.asigDataAge()
    this.asigDataCountries()
    this.asigDataType()
    this.asigDataFollowers()
  },
  methods: {
    maxPercentage() {
      let maxPercentage = 0;
      for (let i = 0; i < this.series_gender.length; i++) {
        if (this.series_gender[i] > maxPercentage) {
          maxPercentage = this.series_gender[i];
        }
      }
      return maxPercentage
    },
    getGraphic() {
      this.genders.title.text = this.title_graphic_genders = JSON.parse(this.title_graphic_genders)
      this.data_genders = JSON.parse(this.data_genders)
      this.genders.labels = this.name_graphics_1 = JSON.parse(this.name_graphics_1)
      this.genders.colors = this.colors_graphics_1 = JSON.parse(this.colors_graphics_1)
      this.asigData()
      this.show_modal = false
      this.change_modal = false
      let maxIndex = 0;
      for (let i = 1; i < this.series_gender.length; i++) {
        if (this.series_gender[i] > this.series_gender[maxIndex]) {
          maxIndex = i;
        }
      }
      this.genders.plotOptions.pie.donut.labels.total.label = this.name_graphics_1[maxIndex];
    },
    asigData() {
      let total = 0;

      for (let i = 0; i < this.data_genders.length; i++) {
        total += this.data_genders[i];
      }

      for (let i = 0; i < this.data_genders.length; i++) {
        this.series_gender[i] = parseFloat(utils.numberFormat((this.data_genders[i] / total) * 100));
      }
      this.name_graphics_1 = this.genders.labels
      this.genders.colors = this.colors_graphics_1
      this.change_graphics = !this.change_graphics
    },
    getGraphicAge() {
      this.data_ageGenders = JSON.parse(this.data_ageGenders) 
      this.chartOptions.xaxis.title.text = this.title_graphic_age = JSON.parse(this.title_graphic_age)
      this.chartOptions.xaxis.categories = this.categories_graphic_2 = JSON.parse(this.categories_graphic_2) 
      this.chartOptions.colors = this.colors_graphics_2 = JSON.parse(this.colors_graphics_2)
      this.asigDataAge()
      this.show_modal_age = false
      this.change_modal_age = false
    },
    asigDataAge() {
      for (let i = 0; i < this.data_ageGenders.length; i++) {
        if (this.series[i]) {
          this.series[i].data = this.data_ageGenders[i].data;
          this.series[i].name = this.data_ageGenders[i].name;
        } else {
          this.series.push({
            data: this.data_ageGenders[i].data,
            name: this.data_ageGenders[i].name
          });
        }
      }
      this.chartOptions.xaxis.title.text = this.title_graphic_age
      this.chartOptions.colors = this.colors_graphics_2
      this.change_graphics_age = !this.change_graphics_age
    },
    showModal() {
      this.show_modal = this.change_modal = true
      this.name_graphics_1 = JSON.stringify(this.name_graphics_1)
      this.data_genders = JSON.stringify(this.data_genders)
      this.title_graphic_genders = JSON.stringify(this.title_graphic_genders)
      this.colors_graphics_1 = JSON.stringify(this.colors_graphics_1)
    },
    closeModal() {
      this.show_modal = this.change_modal = false
      this.data_genders = JSON.parse(this.data_genders)
      this.name_graphics_1 = JSON.parse(this.name_graphics_1)
      this.title_graphic_genders = JSON.parse(this.title_graphic_genders)
      this.colors_graphics_1 = JSON.parse(this.colors_graphics_1)
    },
    showModalAge() {
      this.title_graphic_age = JSON.stringify(this.title_graphic_age)
      this.data_ageGenders = JSON.stringify(this.series)
      this.categories_graphic_2 = JSON.stringify(this.categories_graphic_2)
      this.colors_graphics_2 = JSON.stringify(this.colors_graphics_2)
      this.show_modal_age = true
      this.change_modal_age = true
    },
    closeModalAge() {
      this.show_modal_age = false
      this.change_modal_age = false
      this.data_ageGenders = JSON.parse(this.data_ageGenders) 
      this.title_graphic_age = JSON.parse(this.title_graphic_age)
      this.colors_graphics_2 = JSON.parse(this.colors_graphics_2)
      this.categories_graphic_2 = JSON.parse(this.categories_graphic_2) 
    },
    asigDataCountries() {
      for (let i = 0; i < this.data_countries.length; i++) {
        if (this.series_countries[i]) {
          this.series_countries[i].data = this.data_countries[i].data;
          this.series_countries[i].name = this.data_countries[i].name;
        } else {
          this.series.push({
            data: this.data_countries[i].data,
            name: this.data_countries[i].name
          });
        }
      }
      this.chart_options_countries.country.title.text = this.title_graphic_countries
      this.change_graphics_countries = !this.change_graphics_countries
    },
    showModalCountries() {
      this.title_graphic_countries = JSON.stringify(this.title_graphic_countries)
      this.data_countries = JSON.stringify(this.series_countries)
      this.categories_countries = JSON.stringify(this.categories_countries)
      this.show_modal_countries = true
      this.change_modal_countries = true
    },
    closeModalCountries() {
      this.show_modal_countries = false
      this.change_modal_countries = false
      this.data_countries = JSON.parse(this.data_countries) 
      this.title_graphic_countries = JSON.parse(this.title_graphic_countries)
      this.categories_countries = JSON.parse(this.categories_countries) 
    },
    getGraphicCountries() {
      this.data_countries = JSON.parse(this.data_countries) 
      this.chart_options_countries.country.title.text = this.title_graphic_countries = JSON.parse(this.title_graphic_countries)
      this.chart_options_countries.country.xaxis.categories = this.categories_countries = JSON.parse(this.categories_countries) 
      this.asigDataCountries()
      this.show_modal_countries = false
      this.change_modal_countries = false
    },
    showModalType() {
      this.title_graphic_type = JSON.stringify(this.title_graphic_type)
      this.data_type = JSON.stringify(this.series_type)
      this.categories_type = JSON.stringify(this.categories_type)
      this.show_modal_type = true
      this.change_modal_type = true
    },
    asigDataType() {
      for (let i = 0; i < this.data_type.length; i++) {
        if (this.series_type[i]) {
          this.series_type[i].data = this.data_type[i].data;
          this.series_type[i].name = this.data_type[i].name;
        } else {
          this.series.push({
            data: this.data_type[i].data,
            name: this.data_type[i].name
          });
        }
      }
      this.chart_options_type.type.title.text = this.title_graphic_type
      this.change_graphics_type = !this.change_graphics_type
    },
    closeModalType() {
      this.show_modal_type = false
      this.change_modal_type = false
      this.data_type = JSON.parse(this.data_type) 
      this.title_graphic_type = JSON.parse(this.title_graphic_type)
      this.categories_type = JSON.parse(this.categories_type) 
    },
    getGraphicType() {
      this.data_type = JSON.parse(this.data_type) 
      this.chart_options_type.type.title.text = this.title_graphic_type = JSON.parse(this.title_graphic_type)
      this.chart_options_type.type.xaxis.categories = this.categories_type = JSON.parse(this.categories_type) 
      this.asigDataType()
      this.show_modal_type = false
      this.change_modal_type = false
    },
    getGraphicFollowers() {
      this.data_followers = JSON.parse(this.data_followers) 
      this.chart_options_followers.followers.title.text = this.title_graphic_followers = JSON.parse(this.title_graphic_followers)
      this.chart_options_followers.followers.xaxis.categories = this.categories_followers = JSON.parse(this.categories_followers) 
      this.asigDataFollowers()
      this.show_modal_followers = false
      this.change_modal_followers = false
    },
    asigDataFollowers() {
      for (let i = 0; i < this.data_followers.length; i++) {
        if (this.series_followers[i]) {
          this.series_followers[i].data = this.data_followers[i].data;
          this.series_followers[i].name = this.data_followers[i].name;
        } else {
          this.series.push({
            data: this.data_followers[i].data,
            name: this.data_followers[i].name
          });
        }
      }
      this.chart_options_followers.followers.title.text = this.title_graphic_followers
      this.change_graphics_followers = !this.change_graphics_followers
    },
    closeModalFollowers() {
      this.show_modal_followers = false
      this.change_modal_followers = false
      this.data_followers = JSON.parse(this.data_followers) 
      this.title_graphic_followers = JSON.parse(this.title_graphic_followers)
      this.categories_followers = JSON.parse(this.categories_followers) 
    },
    showModalFollowers() {
      this.title_graphic_followers = JSON.stringify(this.title_graphic_followers)
      this.data_followers = JSON.stringify(this.series_followers)
      this.categories_followers = JSON.stringify(this.categories_followers)
      this.show_modal_followers = true
      this.change_modal_followers = true
    },
  },
};
</script>
    
<style>
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255);
  z-index: 1000;
  right: 5px;
}
.loader-table-cards {
  z-index: 10;
  display: flex;
  justify-content: center;
}
</style>